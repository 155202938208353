import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ProjectList from '../components/Projects/ProjectList';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';
import { GlobalCta } from '../components/Acf/GlobalCta'
const Category = (props) => {
  const { data, pageContext, location } = props
  const { postsOnPage, categories, categoryFilter, site, yoast = [], wordpressWpSettings } = data
  const { wordpressUrl } = wordpressWpSettings
  const { edges: posts, totalCount } = postsOnPage
  const { title: siteTitle } = wordpressWpSettings
  const { name: category, slug, pathPrefix } = pageContext
  const title = `${category} Category`

  return (
    <Layout className={`page-wrap`} wordpressUrl={wordpressUrl} location={location}>
      <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} yoast={yoast}
        location={location}
      />
      <PageHeader headerTitle={title} headerBackgroundImage="" location={location} />
      <ProjectList
        posts={posts}
        title={title}
        pageContext={pageContext}
        categories={categories.edges}
        siteMetadata={wordpressWpSettings}
        pathPrefix={pathPrefix}
      />
      <GlobalCta/>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query ProjectCategoryPage($category_id: [Int], $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    postsOnPage: allWordpressWpProjects(
      filter: {project_category: {elemMatch: {wordpress_id: {in: $category_id}}}}
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          acf {
            title
            featureImage {
              source_url
            }
            categoryPageDescription
            categoryHeaderImage {
              source_url
            }
          }
          wordpress_id
          wordpress_parent
        }
      }
    }
  }
`
