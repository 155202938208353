import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import '../../Blog/PostListNavigation/PostListNavigation.scss'

export const ProjectListNavigation = (props) => {
    const { categories } = props;
    return (
      <section className="post-list-navigation">
        <div className="inner">
          <ul>
            <li><GatsbyLink to={`/projects/`} activeClassName="active">All</GatsbyLink></li>
            {categories && categories.map(({node: category} , index) => category.slug !== 'uncategorised' && (
              <li key={index}><GatsbyLink to={category.path} activeClassName="active" decode={true}>{category.name}</GatsbyLink></li>
            ))}
          </ul>
        </div>
      </section>
    )
}
